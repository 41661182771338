import React from 'react';

import Layout from '@common/Layout';
import {
  ContentContainer,
  ContentMain,
  Row,
  TextBodyWrapper,
  StyledH1,
} from '../components/global';
import Navbar from '@common/Navbar';
import Footer from '@sections/Footer';

const NotFoundPage = () => (
  <Layout>
    <Navbar />
    <ContentMain>
      <ContentContainer>
        <Row>
          <TextBodyWrapper>
            <StyledH1>Seite nicht gefunden!</StyledH1>
            <p>Die aufgerufene Seite ist leider nicht vorhanden.</p>
          </TextBodyWrapper>
          {/* <Link to="/">Zurück</Link> */}
          {/* <LegalTextBodyWrapper>
              <TextBodyWrapper>
                <StyledH1>{frontmatter.title}</StyledH1>
                <div dangerouslySetInnerHTML={{ __html: html }} />
              </TextBodyWrapper>
            </LegalTextBodyWrapper> */}
        </Row>
      </ContentContainer>
    </ContentMain>
    <Footer />
  </Layout>
);

export default NotFoundPage;
